<template>
  <phi-page class="state-popups">
    <div slot="toolbar">
      <h1>Popups</h1>
    </div>

    <ui-field label="URL">
      <input
        type="text"
        v-model="targetUrl"
      />
    </ui-field>

    <ui-field label="Target">
      <input
        type="text"
        v-model="targetName"
      />
    </ui-field>

    <ui-field label="Attributes">
      <input
        type="text"
        v-model="popupAttributes"
      />
    </ui-field>

    <button type="button" class="ui-button --main" @click="openPopup()">Abrir popup</button>
    <button type="button" class="ui-button --cancel" :disabled="!ref" @click="ref.close()">Cerrar popup</button>

    <fieldset>
      <legend>Eventos</legend>
      <pre>{{ eventLog }}</pre>
    </fieldset>
  </phi-page>
</template>

<script>
import * as popup from "@/modules/ecommerce/helpers/popupWindow.js";
import { UiField } from "@/modules/ui/components";

export default {
  name: "state-popups",

  components: { UiField },

  data() {
    return {
      targetUrl: "https://appmovil.phidias.co/billing/test/popup",
      targetName: "_blank",
      popupAttributes:
        "height=768,width=1024,resizable=yes,toolbar=yes,status=yes,location=yes",
      eventLog: [],

      ref: null
    };
  },

  methods: {
    openPopup() {
      this.ref = popup.open(
        this.targetUrl,
        this.targetName,
        this.popupAttributes,
        this.onPopupMessage,
        this.onPopupClose
      );
    },

    onPopupMessage(msg) {
      this.eventLog.push(msg);
    },

    onPopupClose() {
      this.eventLog.push("CLOSED");
    }
  }
};
</script>